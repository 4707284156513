import { render, staticRenderFns } from "./Greetings.html?vue&type=template&id=5d8ff6ef&scoped=true&external"
import script from "./Greetings.js?vue&type=script&lang=js&external"
export * from "./Greetings.js?vue&type=script&lang=js&external"
import style0 from "./Greetings.scss?vue&type=style&index=0&id=5d8ff6ef&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8ff6ef",
  null
  
)

/* custom blocks */
import block0 from "./locales.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2FSettings%2FCallCenter%2FGreetings%2Findex.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports