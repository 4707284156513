import AudioRecorder from "@/components/AudioRecorder/index.vue";
import AudioPlayer from "@/components/AudioPlayer/index.vue";
import GreetingService from "@/services/greeting.service";
import RecordService from "@/services/audio.record.service";
import images from '@/helpers/images';

const service = new GreetingService();
const recordService = new RecordService();

export default {
    name: 'greetings',
    components: {
        AudioRecorder,
        AudioPlayer
    },
    props: [],
    data() {
        return {
            greetings: [],
            createModal: {
                shown: false,
                editing: false,
                record: {
                    EN: null,
                    ES: null
                },
                greeting: {
                    Record: {
                        ENGLISH: null,
                        SPANISH: null,
                    },
                    Text: {
                        ENGLISH: '',
                        SPANISH: ''
                    },
                    DefaultLanguage: 'ENGLISH',
                    Title: '',
                    Description: '',
                    Type: 'Text-To-Speech'
                }
            },
            deleteModal: {
                shown: false,
                greeting: null
            },
            tabsCreate: 'text',
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
        }
    },
    computed: {
        images() {
            return images;
        }
    },
    mounted() {
        this.list();
    },
    methods: {
        async list(page) {
            this.currentPage = page || 1;
            let skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip);
            if (res && !res.error) {
                this.greetings = res.data.greetings;
                this.totalRows = res.data.count;
            }
        },

        showEdit(greeting) {
            this.createModal.greeting = greeting;
            this.createModal.editing = true;
            this.createModal.shown = true;
        },

        closeCreateModal() {
            this.createModal = {
                shown: false,
                editing: false,
                record: {
                    EN: null,
                    ES: null
                },
                greeting: {
                    Record: {
                        ENGLISH: null,
                        SPANISH: null,
                    },
                    Text: {
                        ENGLISH: '',
                        SPANISH: ''
                    },
                    DefaultLanguage: 'ENGLISH',
                    Title: '',
                    Description: '',
                    Type: 'Text-To-Speech'
                }
            };
        },

        recordEN(data) {
            this.createModal.record.EN = data.blob;
        },
        recordES(data) {
            this.createModal.record.ES = data.blob;
        },

        async save() {
            if (this.createModal.record.EN) {
                const $data = new FormData();
                $data.append('name', this.createModal.greeting.Title + '_en');
                $data.append('file', this.createModal.record.EN);
                $data.append('type', 'greeting');

                const res = await recordService.create($data);

                if (res && !res.error) {
                    this.createModal.greeting.Record.ENGLISH = res.data._id;
                }
            }

            if (this.createModal.record.ES) {
                const $data = new FormData();
                $data.append('name', this.createModal.greeting.Title + '_es');
                $data.append('file', this.createModal.record.ES);
                $data.append('type', 'greeting');

                const res = await recordService.create($data);

                if (res && !res.error) {
                    this.createModal.greeting.Record.SPANISH = res.data._id;
                }
            }

            let res = null;
            if (this.createModal.editing)
                res = await service.update(this.createModal.greeting._id, this.createModal.greeting);
            else {
                res = await service.create(this.createModal.greeting);
            }

            if (res && !res.error) {
                this.list();
                this.closeCreateModal();
                this.$notify({
                    type: 'success',
                    message: this.$t('greeting_saved')
                });
            } else {
                this.$notify({
                    type: 'error',
                    message: this.$t('error_saving_greeting')
                });
            }
        },

        showRemoveModal(greeting) {
            this.deleteModal.greeting = greeting;
            this.deleteModal.shown = true;
        },

        closeRemoveModal() {
            this.deleteModal.shown = false;
            this.deleteModal.greeting = null;
        },

        async remove() {
            const res = await service.remove(this.deleteModal.greeting._id);

            if (res && !res.error) {
                this.list();
                this.closeRemoveModal();
                this.$notify({
                    type: 'success',
                    message: this.$t('greeting_removed')
                });
            } else {
                this.$notify({
                    type: 'error',
                    message: this.$t('error_removing_greeting')
                });
            }
        },

        audioUrl(greeting, lang) {
            if (greeting.Type === 'Voice') {
                if (lang) {
                    const file = greeting.Record[lang] ? greeting.Record[lang].File : null;
                    if (!file) return null;
                    return this.$calls_server() + '/api/files/' + file;
                } else {
                    try {
                        const file = greeting.Record[greeting.DefaultLanguage].File;
                        return this.$calls_server() + '/api/files/' + file;
                    } catch (e) {
                        return this.$calls_server() + '/api/files/__no_file__';
                    }

                }

            }

            return null;
        }
    }
}
